import React, { useState } from "react";
import { Card, Table, Tooltip, Tag, Descriptions, Switch, Spin } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import styled from "styled-components";
import PropTypes from "prop-types";
import moment from "moment";

import {
  selectors as NODE_PLAYOUT_PLAYLIST_SELECTORS,
  MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR,
  MEMOIZED_PLAYOUT_PLAYLIST_FIRST_THREE_SELECTOR,
} from "../../ducks/nodePlayoutPlaylist";

import NodePlayoutPlaylistsStatus from "./NodePlayoutPlaylistsStatus";
import { selectors as NODE_CHANNELS_SELECTOR } from "../../ducks/nodeChannels";

import themeColors from "../../lib/style/theme";
import { NODE_PLAYOUT_PLAYLIST_TYPE } from "../../lib/utils/constants";
import PreviewImage from "../PreviewImage";

function ExpandedRow(record) {
  return <pre>{JSON.stringify({ cgEvents: record?.cgEvents }, null, 2)}</pre>;
}

ExpandedRow.propTypes = {
  record: PropTypes.shape({
    cgEvents: PropTypes.string,
  }).isRequired,
};

const columns = [
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.date" defaultMessage="DATE" />,
    dataIndex: "time",
    key: "time",
    render: (time, record) => (
      <Tooltip placement="top" title={record?.date}>
        <span>{time}</span>
      </Tooltip>
    ),
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.name" defaultMessage="NAME" />,
    dataIndex: "clipName",
    key: "clipName",
    render: (clipName, record) => (
      <span>
        <StyledSpan>{record?.clipName}</StyledSpan>
        {record?.fileName ? ` (${record.fileName})` : ""}
      </span>
    ),
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.type" defaultMessage="TYPE" />,
    dataIndex: "type",
    key: "type",
    align: "center",
    render: (type) => {
      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.FILE) {
        return (
          <Tag bordered={false} color={themeColors.defaultBlueColor}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.AD_BREAK) {
        return (
          <Tag bordered={false} color={themeColors.orange}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.LIVE) {
        return (
          <Tag bordered={false} color={themeColors.red}>
            {type}
          </Tag>
        );
      }

      if (type === NODE_PLAYOUT_PLAYLIST_TYPE.PLACEHOLDER) {
        return (
          <Tag bordered={false} color={themeColors.maritime}>
            {type}
          </Tag>
        );
      }

      return <Tag bordered={false}>{type}</Tag>;
    },
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.statusUppercase" defaultMessage="STATUS" />,
    dataIndex: "itemStatus",
    key: "itemStatus",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.extAudioStatus" defaultMessage="EXT. AUDIO" />,
    dataIndex: "extAudioStatus",
    key: "extAudioStatus",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.duration" defaultMessage="DURATION" />,
    dataIndex: "formattedDuration",
    key: "formattedDuration",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.tcIn" defaultMessage="TC IN" />,
    dataIndex: "formattedTcIn",
    key: "formattedTcIn",
    align: "center",
  },
  {
    title: <FormattedMessage id="NodePlayoutPlaylists.tcOut" defaultMessage="TC OUT" />,
    dataIndex: "formattedTcOut",
    key: "formattedTcOut",
    align: "center",
  },
];

const NodePlayoutPlaylists = () => {
  const { id } = useParams();
  const playoutPlaylist = useSelector((state) => MEMOIZED_PLAYOUT_PLAYLIST_SELECTOR(state, +id));
  const parsedPlayoutPlaylist = useSelector((state) => MEMOIZED_PLAYOUT_PLAYLIST_FIRST_THREE_SELECTOR(state, +id));

  const apiChannel = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistApiChannel(state, +id));
  const apiConnected = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistApiConnected(state, +id)
  );
  const cgMode = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgMode(state, +id));
  const cgControl = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgControl(state, +id));
  const cgSource = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgSource(state, +id));
  const cgControlStatus = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgControlStatus(state, +id)
  );
  const cgSourceStatus = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistCgSourceStatus(state, +id)
  );
  const elemPath0 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath0(state, +id));
  const elemStatus0 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus0(state, +id));
  const elemPath20 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath20(state, +id));
  const elemStatus20 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus20(state, +id)
  );
  const elemPath21 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath21(state, +id));
  const elemStatus21 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus21(state, +id)
  );
  const elemPath22 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemPath22(state, +id));
  const elemStatus22 = useSelector((state) =>
    NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPlaylistElemStatus22(state, +id)
  );
  const elemPreview20 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPreviewElem20(state, +id));
  const elemPreview21 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPreviewElem21(state, +id));
  const elemPreview22 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPreviewElem22(state, +id));
  const elemPreview23 = useSelector((state) => NODE_PLAYOUT_PLAYLIST_SELECTORS.getPlayoutPreviewElem23(state, +id));

  const isChannelStarted = useSelector((state) => NODE_CHANNELS_SELECTOR.getIsChannelStarted({ state, channelId: id }));

  const [showMoreData, setShowMoreData] = useState(false);

  const items = [
    {
      key: "live",
      label: (
        <>
          <span>
            <FormattedMessage id="NodePlayoutPlaylists.live" defaultMessage="Live" />:
          </span>{" "}
          <StyledTitle>{elemPath22}</StyledTitle>
        </>
      ),
      children: (
        <div>
          <PreviewImage src={elemPreview22} alt="Head 3" />
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus22} />
          </div>
        </div>
      ),
    },
    {
      key: "elemPath20",
      label: (
        <>
          <span>
            <FormattedMessage id="NodePlayoutPlaylists.head1" defaultMessage="Head 1" />:
          </span>{" "}
          <StyledTitle>{elemPath20}</StyledTitle>
        </>
      ),
      children: (
        <div>
          <PreviewImage src={elemPreview20} alt="Head 1" />
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus20} />
          </div>
        </div>
      ),
    },
    {
      key: "elemPath21",
      label: (
        <>
          <span>
            <FormattedMessage id="NodePlayoutPlaylists.head2" defaultMessage="Head 2" />:
          </span>{" "}
          <StyledTitle>{elemPath21}</StyledTitle>
        </>
      ),
      children: (
        <div>
          <PreviewImage src={elemPreview21} alt="Head 2" />
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus21} />
          </div>
        </div>
      ),
    },
    {
      key: "elemPath0",
      label: (
        <>
          <span>
            <FormattedMessage id="NodePlayoutPlaylists.switcher" defaultMessage="Switcher" />:
          </span>{" "}
          <StyledTitle>{elemPath0}</StyledTitle>
        </>
      ),
      children: (
        <div>
          <PreviewImage src={elemPreview23} alt="Switcher" />
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={elemStatus0} />
          </div>
        </div>
      ),
    },
    {
      key: "apiChannel",
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 },
      label: <FormattedMessage id="NodePlayoutPlaylists.apiChannel" defaultMessage="Api channel" />,
      children: (
        <div>
          <div>{apiChannel}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={apiConnected} />
            <div>
              <FormattedMessage id="NodePlayoutPlaylists.utcTime" defaultMessage="UTC Time" />:{" "}
              {moment.utc().format("HH:mm:ss")}
            </div>
          </div>
        </div>
      ),
    },
    {
      key: "cgMode",
      label: <FormattedMessage id="NodePlayoutPlaylists.cg" defaultMessage="CG" />,
      span: { xs: 1, sm: 1, md: 1, lg: 1, xl: 1, xxl: 2 },
      children: (
        <div>
          <div>{cgMode}</div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.control" defaultMessage="Control" />: {cgControl}
            {", "}
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={cgControlStatus} />
          </div>
          <div>
            <FormattedMessage id="NodePlayoutPlaylists.source" defaultMessage="Source" />: {cgSource}
            {", "}
            <FormattedMessage id="NodePlayoutPlaylists.status" defaultMessage="Status" />:{" "}
            <NodePlayoutPlaylistsStatus status={cgSourceStatus} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Spin
      tip={
        <FormattedMessage id="NodePlayoutPlaylists.pleaseStartChannel" defaultMessage="Please start channel first" />
      }
      spinning={!isChannelStarted}
      indicator={<WarningOutlined />}
      size="large"
    >
      <Card>
        <Descriptions
          items={items}
          labelStyle={{ color: "#FFF" }}
          column={{ xs: 1, sm: 1, md: 1, lg: 2, xl: 2, xxl: 4 }}
          bordered
          layout="vertical"
        />
        <StyledCard
          title={<FormattedMessage id="NodePlayoutPlaylists.playlist" defaultMessage="Playlist" />}
          extra={
            <Switch
              checkedChildren={<FormattedMessage id="NodePlayoutPlaylists.lessData" defaultMessage="Less data" />}
              unCheckedChildren={<FormattedMessage id="NodePlayoutPlaylists.modeData" defaultMessage="More data" />}
              checked={showMoreData}
              onChange={(checked) => {
                setShowMoreData(checked);
              }}
            />
          }
        >
          <Table
            pagination={false}
            dataSource={showMoreData ? playoutPlaylist : parsedPlayoutPlaylist}
            columns={columns}
            expandable={{
              expandedRowRender: ExpandedRow,
              rowExpandable: (record) => !!record?.cgEvents,
              expandRowByClick: true,
            }}
            rowKey={(record) => record?.guid}
          />
        </StyledCard>
      </Card>
    </Spin>
  );
};

const StyledSpan = styled.span`
  color: ${themeColors.orange};
`;

const StyledTitle = styled.span`
  color: ${themeColors.maritime};
`;

const StyledCard = styled(Card)`
  margin-top: 16px;
`;

export default NodePlayoutPlaylists;
